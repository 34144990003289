var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.record.action_log && _vm.record.action_log.length
    ? _c(
        "div",
        {
          staticClass: "tabe-tip-box",
          class: [_vm.lang],
          staticStyle: { margin: "0" },
          on: {
            click: function($event) {
              $event.stopPropagation()
            }
          }
        },
        [
          _c("div", { staticClass: "left-time-box", class: [_vm.lang] }, [
            _vm._v(" " + _vm._s(_vm.recordTime) + " ")
          ]),
          _c("div", { staticClass: "line", class: [_vm.lang] }),
          _c(
            "div",
            {
              staticClass: "status-box",
              class:
                ((_obj = {}),
                (_obj[_vm.lang] = true),
                (_obj["status-active"] = _vm.record.work_status == 1),
                _obj)
            },
            [
              _vm.record.action_log && _vm.record.action_log[0]
                ? _c(
                    "div",
                    { staticClass: "txt-box" },
                    [
                      _c("svg-icon", {
                        staticClass: "img-icon",
                        attrs: { iconClass: "robot-icon" }
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "status-txt",
                          class: [_vm.lang],
                          style: {
                            "text-align": _vm.lang == "AR" ? "right" : "left"
                          }
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.record.work_status == 1
                                  ? _vm.$t("statusActive")
                                  : _vm.$t("statusPaused")
                              )
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "txt-right",
                  style: {
                    "text-align": _vm.lang == "AR" ? "right" : "left"
                  }
                },
                [
                  _c("div", { staticClass: "txt" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.record.action_log &&
                            _vm.record.action_log[0] &&
                            _vm.record.action_log[0].content
                        ) +
                        " "
                    )
                  ]),
                  _c(
                    "a-popover",
                    {
                      staticStyle: { "z-index": "98" },
                      attrs: {
                        placement: "rightTop",
                        trigger: "click",
                        overlayClassName: "campaign-table-tip-popover"
                      },
                      model: {
                        value: _vm.visible,
                        callback: function($$v) {
                          _vm.visible = $$v
                        },
                        expression: "visible"
                      }
                    },
                    [
                      _c("template", { slot: "content" }, [
                        _c(
                          "div",
                          { staticClass: "history-box" },
                          _vm._l(_vm.record.action_log, function(item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "history-txt",
                                class: [_vm.lang],
                                style: {
                                  "text-align":
                                    _vm.lang == "AR" ? "right" : "left"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "time-box",
                                    class: [_vm.lang]
                                  },
                                  [
                                    _c("div", { staticClass: "dian" }),
                                    _vm._v(_vm._s(item.create_date) + " ")
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "tip-txt", class: [_vm.lang] },
                                  [_vm._v(" " + _vm._s(item.content) + " ")]
                                )
                              ]
                            )
                          }),
                          0
                        )
                      ]),
                      _c("span", { staticClass: "history-icon-box" }, [
                        _c(
                          "span",
                          { staticClass: "icon-txt" },
                          [
                            _c("a-icon", {
                              staticClass: "history-icon",
                              attrs: { type: "clock-circle", theme: "filled" }
                            }),
                            _vm._v(" " + _vm._s(_vm.$t("historyBtn")) + " ")
                          ],
                          1
                        )
                      ])
                    ],
                    2
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }