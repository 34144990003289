<template>
  <!-- campaign 列表状态提示 -->
	<div style="margin: 0;" class="tabe-tip-box" @click.stop="" :class="[lang]" v-if="record.action_log && record.action_log.length">
		<div class="left-time-box" :class="[lang]">
			{{ recordTime }}
		</div>
		<div class="line" :class="[lang]"></div>
		<div
			class="status-box"
			:class="{
				[lang]: true,
				'status-active': record.work_status == 1,
			}"
		>
			<div class="txt-box" v-if="record.action_log && record.action_log[0]">
				<!-- <img src="" alt=""> -->
				<svg-icon
          iconClass="robot-icon"
          class="img-icon"
        ></svg-icon>
				<div
					class="status-txt"
					:class="[lang]"
					:style="{
						'text-align': lang == 'AR' ? 'right' : 'left',
					}"
				>
					<span>{{ record.work_status == 1 ? $t('statusActive') : $t('statusPaused') }}</span>
				</div>
			</div>
			<div
				class="txt-right"
				:style="{
					'text-align': lang == 'AR' ? 'right' : 'left',
				}"
			>
				<div class="txt">
					{{ record.action_log && record.action_log[0] && record.action_log[0].content }}
				</div>
				<a-popover
					placement="rightTop"
					style="z-index: 98"
					trigger="click"
					v-model="visible"
					overlayClassName="campaign-table-tip-popover"
				>
					<template slot="content">
						<div class="history-box">
							<div
								class="history-txt"
								:class="[lang]"
								:style="{
									'text-align': lang == 'AR' ? 'right' : 'left',
								}"
								v-for="(item, index) in record.action_log"
								:key="index"
							>
								<div class="time-box" :class="[lang]">
									<div class="dian"></div>{{ item.create_date }}
								</div>
								<div class="tip-txt" :class="[lang]">
									{{ item.content }}
								</div>
							</div>
						</div>
					</template>
					<span class="history-icon-box">
						<span class="icon-txt">
							<a-icon
								type="clock-circle"
								theme="filled"
								class="history-icon"
							/>
							{{ $t('historyBtn') }}
						</span>
					</span>
				</a-popover>
			</div>
		</div>
	</div>
</template>

<script>
import dayjs from "dayjs";
import { mapState } from "vuex";

export default {
	name: 'CampaignTableTip',
	props: {
		record: {
			type: Object,
			default: () => ({
				action_log: [],
			})
		}
	},
	i18n: {
    messages: {
      CN: {
        statusActive: "工作中",
        statusPaused: "暂停中",
				historyBtn: '历史记录',
      },

      US: {
        statusActive: "Working",
				statusPaused: "Paused",
				historyBtn: 'History Records',
      },
      AR: {
        statusActive: "قيد العمل",
				statusPaused: "متوقف مؤقتاً",
				historyBtn: 'سجلات التاريخ',
      },
    },
  },
	data() {
		return {
			visible: false
		}
	},
	computed: {
    ...mapState("setting", ["layout", "theme", "weekMode", "lang"]),
		recordTime () {
			let time = this.record.action_log && this.record.action_log[0] && this.record.action_log[0].create_date;
			if (time) {
				return dayjs(time).format("HH:mm");
			}
			return '--';
		},
  },
	methods: {
		handleVisibleChange() {
			// this.visible = !this.visible
		}
	},
}
</script>

<style lang="less" scoped>
.tabe-tip-box {
	position: absolute;
	padding-left: 8px;
	width: calc(100%);
	// left: 200px;
	color: #000;
	left: 0;
	bottom: 8px;
	cursor: default;
	&::after {
		content: "";
		clear: both;
	}
	.left-time-box {
		font-weight: 400;
		font-size: 10px;
		color: #000000;
		// text-align: left;
		font-style: normal;
		text-transform: none;
		width: 36px;
		min-width: 36px;
		margin-top: -2px;
		float: left;
	}
	.line {
		float: left;
		width: 9px;
		min-width: 9px;
		height: 9px;
		background: #FFFFFF;
		border: 2px solid #20AE9F;
		border-radius: 50%;
		position: relative;
		&::after {
			content: '';
			position: absolute;
			top: 7px;
			width: 1px;
			height: 28px;
			left: 50%;
			transform: translate(-50%);
			background: linear-gradient( 180deg, #20AE9F 0%, rgba(32,174,159,0) 100%);
		}
		margin-right: 6px;
	}
	.status-box {
		float: left;
		background: #E5E9EC;
		padding: 5px 8px;
		border-radius: 4px;
		width: calc(100% - 52px);
		.txt-box {
			display: inline-block;
			vertical-align: top;
			line-height: 20px;
			height: 20px;
			img {
				width: 20px;
				min-width: 20px;
				height: 20px;
				vertical-align: top;
			}
			.img-icon {
				font-size: 20px;
				vertical-align: top;
			}
			.status-txt {
				display: inline-block;
				margin: 2px 10px 2px 8px;
				min-width: 40px;
				height: 16px;
				line-height: 14px;
				padding: 0 4px;
				color: #fff;
				background: #FBC925;
				border-radius: 2px 2px 2px 2px;
				border: 1px solid #E3AF04;
				font-weight: 800;
				text-align: center;
				position: relative;
				vertical-align: top;
				span {
					font-size: 12px;
					// position: absolute;
					// left: 50%;
					// top: 50%;
					letter-spacing: 1px;
					display: block;
					line-height: 17px;
					// transform: translate(-50%, -50%) scale(0.8);
					zoom: 0.8;
				}
			}
		}
		.txt-right {
			display: inline-block;
			.txt {
				font-weight: 500;
				font-size: 12px;
				color: #949EA8;
				line-height: 17px;
			}
		}
		.history-icon-box {
			// margin-left: 78px;
			font-weight: bold;
			font-size: 12px;
			color: #0E756A;
			padding-top: 4px;
			cursor: pointer;
			.icon-txt {
				zoom: 0.8;
			}
			.history-icon {
				margin-right: 2px;
			}
		}
		&.status-active {
			background: #CEE6DC;
			.status-txt {
				background: linear-gradient( 267deg, #12C5A7 0%, #12C5A7 100%);
				border: 1px solid #38D2B8;
			}
			.txt {
				color: #000;
			}
		}
	}
}
.history-box {
	width: 304px;
	max-height: 410px;
	overflow-y: scroll;
	padding: 10px 12px;
	.history-txt {
		padding: 16px 0;
		border-top: 1px solid #E5E5E5;
		&:first-child {
			padding-top: 0;
			border: none;
		}
		&:last-child {
			padding-bottom: 6px;
		}
		.time-box {
			font-weight: bold;
			font-size: 10px;
			color: #80829F;
			line-height: 14px;
			// padding-left: 16px;
			.dian {
				display: inline-block;
				width: 9px;
				height: 9px;
				background: #FFFFFF;
				border: 2px solid #20AE9F;
				border-radius: 50%;
				margin-right: 7px;
			}
			&.AR .dian {
				margin-right: 0;
				margin-left: 7px;
			}
		}
		.tip-txt {
			padding-left: 16px;
			font-weight: bold;
			font-size: 10px;
			color: #000000;
			line-height: 18px;
			margin-top: 6px;
			&.AR {
				padding: 0 16px 0 0;
			}
		}
	}
}
</style>

<style lang="less">
.campaign-table-tip-popover {
	.ant-popover-inner-content {
		padding: 0;
	}
}
</style>
